/**
 * Created by NM on 8/10/2017.
 */

@use '~@angular/material' as mat;
@import "../../../node_modules/@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "theme-paletes";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$recl-primary: mat.define-palette($primary);
$recl-accent: mat.define-palette($accent, 500, 500, 500);

// The warn palette is optional (defaults to red).
$recl-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$recl-theme: mat.define-light-theme($recl-primary, $recl-accent, $recl-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($recl-theme);

.color-green1 {
  background-color: #32e21f;
  color: white !important;
}
.color-blue1 {
  background-color: #1c1f98;
  color: white !important;
}
