.margin-4 {
  margin: 4px;
}

.margin-8 {
  margin: 8px;
}

// margin-left
.margin-left-0 {
  margin-left: 0 !important;
}

.margin-left-1 {
  margin-left: 1px !important;
}

.margin-left-2 {
  margin-left: 2px !important;
}

.margin-left-4 {
  margin-left: 4px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-left-24 {
  margin-left: 24px !important;
}

// margin-top
.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-1 {
  margin-top: 1px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

// margin-right
.margin-right-0 {
  margin-right: 0 !important;
}

.margin-right-1 {
  margin-right: 1px !important;
}

.margin-right-2 {
  margin-right: 2px !important;
}

.margin-right-4 {
  margin-right: 4px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-right-12 {
  margin-right: 12px !important;
}

.margin-right-16 {
  margin-right: 16px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

// margin-bottom
.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-1 {
  margin-bottom: 1px !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

// margin-topBottom
.margin-topBottom-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.margin-topBottom-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.margin-topBottom-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.margin-topBottom-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.margin-topBottom-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.margin-topBottom-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.margin-topBottom-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.margin-topBottom-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

// margin-leftRight
.margin-leftRight-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.margin-leftRight-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.margin-leftRight-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.margin-leftRight-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.margin-leftRight-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.margin-leftRight-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.margin-leftRight-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.margin-leftRight-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
