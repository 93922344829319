// #region general
.mat-title {
  margin: 0;
}

.align-vertical-center {
  align-self: center;
}

.inline-block {
  display: inline-block;
}

.text-gray {
  color: gray;
}

.white-background {
  background-color: white;
}

.light {
  font-weight: 300;
}

img.logo {
  width: 128px;
  margin-top: 16px;
  margin-bottom: 32px;
}

img.logo-small {
  width: 50px;
  margin: 16px;
}

hr {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

// endregion

// #region dashboard
.dashboard {
  .mat-card {
    display: flex;
    padding: 16px;
  }

  .reservation-card {
    background-color: white;
  }

  .reservationButton {
    margin-top: 4px;
    margin-right: 4px;
    float: left;
  }

  .viewReservationButton {
    margin-top: 4px;
    margin-bottom: 4px;
    float: left;
  }

  @media screen and (min-width: 768px) {
    .resetButton {
      float: right;
    }

    .reservationButton {
      margin-top: 0;
      float: left;
    }

    .viewReservationButton {
      margin-top: 0;
      float: right;
    }

    .img-err {
      width: 10%;
      margin: 16px;
    }
  }

  @media screen and (min-width: 960px) {
    .reservationButton {
      margin-top: 4px;
      float: left;
    }
  }

  @media screen and (max-width: 576px) {
    .img-err {
      width: 25%;
      margin: 16px;
    }
  }

  @media screen and (min-width: 576px) {
    .img-err {
      width: 18%;
      margin: 16px;
    }
  }

  .statistics {
    div {
      margin-bottom: 8px;
    }
  }
}

// #endregion

// #region spinner
.spinner {
  margin: 64px auto;
  height: 2.5em;
}

.small-spinner {
  margin: 20px auto;
  height: 1.2em;
}

// #endregion

// #region table page
.page-container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  min-width: 690px;
  background-color: white;

  .table-title {
    min-height: 64px;
    padding-left: 24px;
    padding-top: 24px;
  }

  .table-search {
    min-height: 64px;
    display: flex;
    align-items: baseline;
    padding: 4px 24px 0;
    justify-content: space-between;

    .mat-form-field {
      font-size: 14px;
      flex-grow: 1;
    }
  }

  .add-button {
    padding-left: 16px;
  }

  .import-button {
    padding-left: 8px;
  }
}

// #endregion

.full-width {
  width: 100%;
}

.spaced-card {
  padding: 10px 21px;
  margin: 15px;
}

.mat-checkbox-no-padding .mat-checkbox-layout {
  margin-bottom: 0;
}
