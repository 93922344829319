/* You can add global styles to this file, and also import other style files */

@import "styles/mat-theme/mat-theme";
@import "styles/margins";
@import "styles/paddings";
@import "styles/shared";

.btn-link {
  cursor: pointer;
}

body {
  font-family: "Roboto", sans-serif !important;
  background-color: rgb(245, 245, 245) !important;
}

.loading {
  display: block;
  margin: auto;
  margin-top: 10%;
  text-align: center;
}

.text-loading {
  font-weight: bold;
  font-size: medium;
  color: gray;
  margin-top: 8px;
}

.pointer-cursor {
  cursor: pointer;
}

.mat-button {
  text-align: inherit !important;
}
