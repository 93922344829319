// padding-left
.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-1 {
  padding-left: 1px !important;
}

.padding-left-2 {
  padding-left: 2px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}

.padding-left-8 {
  padding-left: 8px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

// padding-top
.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-1 {
  padding-top: 1px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.padding-top-16 {
  padding-top: 16px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-24 {
  padding-top: 24px !important;
}

// padding-right
.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-1 {
  padding-right: 1px !important;
}

.padding-right-2 {
  padding-right: 2px !important;
}

.padding-right-4 {
  padding-right: 4px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-right-12 {
  padding-right: 12px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-24 {
  padding-right: 24px !important;
}

// padding-bottom
.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-1 {
  padding-bottom: 1px !important;
}

.padding-bottom-2 {
  padding-bottom: 2px !important;
}

.padding-bottom-4 {
  padding-bottom: 4px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.padding-bottom-12 {
  padding-bottom: 12px !important;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

// padding-topBottom
.padding-topBottom-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-topBottom-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.padding-topBottom-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.padding-topBottom-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.padding-topBottom-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.padding-topBottom-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.padding-topBottom-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.padding-topBottom-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

// padding-leftRight
.padding-leftRight-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-leftRight-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.padding-leftRight-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.padding-leftRight-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.padding-leftRight-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.padding-leftRight-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.padding-leftRight-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.padding-leftRight-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.padding-0 {
  padding: 0px;
}

.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}
